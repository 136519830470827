<!-- Cria modal com inputs da cidade selecionada para alterar valor de frete -->

<template>
  <modal
    name="ModalPropriedadesFreteCidade"
    height="auto"
    margin="300"
    width="980"
    :scrollable="true"
    :clickToClose="false"
  >
    <CloseModal :close="onClose" />

    <div class="modalbox" v-if="cidade">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="cidade.cidade" v-if="cidade.id">
            <div class="row">
              <div class="col-4">
                <div class="form-group">
                  <label for="valor_frete">Valor do frete</label>

                  <money
                    class="form-control"
                    v-model="valor_frete"
                    v-bind="monetary"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="valor_isenta_frete">Valor mín. do pedido para isentar frete</label>
                  <money
                    class="form-control"
                    v-model="valor_isenta_frete"
                    v-bind="monetary"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="valor_minimo_pedido">Valor mín. do pedido para entrega</label>
                  <money
                    class="form-control"
                    v-model="valor_minimo_pedido"
                    v-bind="monetary"
                  />
                </div>
              </div>
                <div class="col">
                    <div class="form-group">
                        <label for="">
                          Dias de Entrega
                          <CButton
                            color="link"
                            size="sm"
                            @click="selecionarTodosDias()"
                          >
                            Selecionar todos
                          </CButton>
                          <CButton
                            color="link"
                            size="sm"
                            @click="selecionarDiasUteis()"
                          >
                            Selecionar dias úteis
                          </CButton>
                          <CButton
                            color="link"
                            size="sm"
                            @click="desmarcarDias()"
                          >
                            &times;
                            Desmarcar tudo
                          </CButton>
                        </label>
                        <div class="form-group">
                          <label class="button checkbox" v-for="(label,chave) in diasSemana">
                            <input type="checkbox" v-model="dias_entrega[chave]" />
                            &nbsp;
                            {{ label }}
                          </label>
                        </div>
                    </div>
                </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <CButton
        color="success"
        @click="onSaveCidade"
      >
        Salvar
      </CButton>
    </div>
  </modal>
</template>

<script>
import CloseModal from "../../components/CloseModal";
import VueNumeric from "vue-numeric";
import _map from "lodash/map";

export default {
  name: "ModalPropriedadesFreteCidade",
  components: { CloseModal, VueNumeric },
  props: [
    "cidade",
    "onClose",
    "addCidade",
    "diasSemana"
  ],
  data() {
    return {
      loading: false,
      dias_entrega: {},
      valor_frete: 0,
      valor_isenta_frete: 0,
      valor_minimo_pedido: 0,
      monetary: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        masked: false,
      },
    };
  },

  methods: {
    selecionarTodosDias() {
      this.dias_entrega.domingo = true;
      this.dias_entrega.segunda = true;
      this.dias_entrega.terca   = true;
      this.dias_entrega.quarta  = true;
      this.dias_entrega.quinta  = true;
      this.dias_entrega.sexta   = true;
      this.dias_entrega.sabado  = true;
      this.dias_entrega         = {...this.dias_entrega};
    },
    selecionarDiasUteis() {
      this.dias_entrega.domingo = false;
      this.dias_entrega.segunda = true;
      this.dias_entrega.terca   = true;
      this.dias_entrega.quarta  = true;
      this.dias_entrega.quinta  = true;
      this.dias_entrega.sexta   = true;
      this.dias_entrega.sabado  = false;
      this.dias_entrega         = {...this.dias_entrega};
    },
    desmarcarDias() {
      this.dias_entrega.domingo = false;
      this.dias_entrega.segunda = false;
      this.dias_entrega.terca   = false;
      this.dias_entrega.quarta  = false;
      this.dias_entrega.quinta  = false;
      this.dias_entrega.sexta   = false;
      this.dias_entrega.sabado  = false;
      this.dias_entrega         = {...this.dias_entrega};
    },
    onSaveCidade() {
      this.addCidade(
        this.cidade,
        this.dias_entrega,
        this.valor_frete,
        this.valor_isenta_frete,
        this.valor_minimo_pedido
      );
      this.onClose();
    },
  },

  watch: {
    cidade: {
      immediate: true,
      handler(newCidade) {
        if (newCidade) {
          this.valor_frete = newCidade.valor_frete || 0;
          this.valor_isenta_frete = newCidade.valor_isenta_frete || 0;
          this.valor_minimo_pedido = newCidade.valor_minimo_pedido || 0;

          _map(this.diasSemana,(label, chave) => {
            this.dias_entrega[chave] = newCidade[chave] || false;
          });
        }
      },
    },
  },
};
</script>
