<template>
  <modal
    name="modalSelecaoCidades"
    height="auto"
    width="900"
    :scrollable="true"
    @before-open="beforeOpen()"
    :clickToClose="false"
  >
    <ModalPropriedadesFreteCidade
      :cidade="cidadeSelecionada"
      :addCidade="addCidade"
      :onClose="onCloseModalPropriedadesFreteCidade"
      :diasSemana="diasSemana"
    />
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Cidades" v-if="item.id">
            <div class="xrow">
              <div class="col-1-2 p-0">
                <span class="clientes filters__search">
                  <input
                      type="text"
                      v-model="filter"
                      v-on:input="debounceloadCidades()"
                      placeholder="Filtrar por UF, cidade ou IBGE"
                  />
                  <fa-icon icon="search" />
                </span>
                <div class="scrollable lista-cidades" style="height: 600px">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead style="height: 55px">
                        <tr>
                          <th colspan="1">Cidades</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="cidade in cidades" v-bind:key="cidade.id">
                          <td colspan="3">
                            <div class="line">
                              <div>
                                <span class="pre-text"
                                  >{{ cidade.cidade }} / {{ cidade.uf }}</span
                                >
                              </div>
                              <div class="actions">
                                <CButton
                                  @click="openModalPropriedadesFreteCidade(cidade)"
                                  color="success"
                                  size="sm"
                                  :disabled="cidadeJaAdicionada(cidade.id)"
                                >
                                  <fa-icon icon="plus" />
                                </CButton>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-1-2">
                <span class="clientes filters__search">
                  <input
                      type="text"
                      v-model="filterVinculadas"
                      placeholder="Filtrar por UF, cidade ou IBGE"
                  />
                  <fa-icon icon="search" />
                </span>
                <div class="scrollable lista-cidades" style="height: 600px">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead style="height: 55px">
                        <tr>
                          <th colspan="1">Cidades Vinculadas</th>
                          <th colspan="1">
                            <div class="actions">
                              <CButton
                                color="danger"
                                size="sm"
                                @click="remTodasCidades()"
                              >
                                <fa-icon icon="trash-alt" />
                              </CButton>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="cidade in cidadesSelecionadasFiltradas"
                          v-bind:key="cidade.id"
                        >
                          <td colspan="3">
                            <div class="line">
                              <div>
                                <span class="pre-text"
                                  >{{ cidade.cidade }} / {{ cidade.uf }}</span
                                >
                                <div class="d-flex mt-2">
                                  <span class="pre-text">
                                    Valor Frete:
                                    <strong>{{ toMonetary(cidade.valor_frete) }}</strong>
                                  </span>
                                  <span class="pre-text mx-2">
                                    Valor Isenta Frete:
                                    <strong>{{toMonetary(cidade.valor_isenta_frete) }}</strong>
                                  </span>
                                  <span class="pre-text">
                                    Valor Minimo Pedido:
                                    <strong>{{toMonetary(cidade.valor_minimo_pedido) }}</strong>
                                  </span>
                                </div>
                                 <div class="d-flex mt-2">
                                  <span class="pre-text">
                                    Dias de entrega:
                                    <strong>{{ cidade | diasEntrega(diasSemana) }}</strong>
                                  </span>
                                 </div>
                              </div>
                              <span class="actions">
                                <CButton
                                  @click="openModalPropriedadesFreteCidade(cidade)"
                                  color="primary"
                                  size="sm"
                                >
                                  <fa-icon icon="pencil-alt" />
                                </CButton>
                                <CButton
                                  color="danger"
                                  size="sm"
                                  @click="remCidade(cidade.id)"
                                >
                                  <fa-icon icon="trash-alt" />
                                </CButton>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <div>
        <CButtonToolbar>
          <CButton
            color="success"
            @click="handleSubmit()"
          >
            Alterar
          </CButton>
          <CButton color="secondary" @click="close">
            Fechar
          </CButton>
        </CButtonToolbar>
      </div>
    </div>
  </modal>
</template>
<script>
import clone from "just-clone";
import { get } from "@/helpers/apiRequest";
import _debounce from "lodash/debounce";
import _find from "lodash/find";
import _reject from "lodash/reject";
import _sortBy from "lodash/sortBy";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _differenceBy from "lodash/differenceBy";
import CloseModal from "../../components/CloseModal";
import ModalPropriedadesFreteCidade from "./ModalPropriedadesFreteCidade";
import {arrayFilter} from "@/helpers/utils";
export default {
  name: "modalSelecaoCidades",
  components: {
    CloseModal,
    ModalPropriedadesFreteCidade,
  },
  props: [
    "update",
    "close",
    "item"
  ],
  data() {
    return {
      filter: "",
      filterVinculadas: "",
      cidades: [],
      cidadeSelecionada: null,
      diasSemana: {
        'domingo': 'Domingo',
        'segunda' : 'Segunda-feira',
        'terca' : 'Terça-feira',
        'quarta' : 'Quarta-feira',
        'quinta' : 'Quinta-feira',
        'sexta' : 'Sexta-feira',
        'sabado' : 'Sábado'
      }
    };
  },
  computed: {
    cidadesSelecionadasFiltradas() {
      return arrayFilter(
          this.item.cidades,
          this.filterVinculadas,
          ['cidade', 'uf', 'ibge', 'id']
      );
    }
  },
  methods: {
    toMonetary(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    beforeOpen() {
      this.loadCidades();
      this.sortCidades();
    },

    onCloseModalPropriedadesFreteCidade() {
      this.$modal.hide("ModalPropriedadesFreteCidade");
    },

    cidadesDisponiveis() {
      return _differenceBy(this.cidades, this.item.cidades, "id");
    },
    sortCidades() {
      this.item.cidades = _sortBy(this.item.cidades, ["uf", "cidade"]);
    },
    openModalPropriedadesFreteCidade(cidade) {
      this.cidadeSelecionada = clone(cidade);
      this.$modal.show("ModalPropriedadesFreteCidade");
    },
    addCidade(
      cidade,
      dias_entrega,
      valor_frete,
      valor_isenta_frete,
      valor_minimo_pedido
    ) {

      cidade.valor_frete = valor_frete;
      cidade.valor_isenta_frete = valor_isenta_frete;
      cidade.valor_minimo_pedido = valor_minimo_pedido;

      _map(this.diasSemana,(label, chave) => {
        cidade[chave] = dias_entrega[chave] || false;
      });

      let index = this.item.cidades.findIndex((c) => c.id === cidade.id);

      if (index !== -1) {
        this.item.cidades[index] = cidade;
      } else {
        this.item.cidades.push(cidade);
      }

      this.sortCidades();
    },
    addTodasCidades() {
      let cidades = this.cidadesDisponiveis();

      for (let cidade of cidades) {
        this.addCidade(cidade, false);
      }
      this.sortCidades();
    },
    cidadeJaAdicionada(idCidade) {
      return _find(this.item.cidades, { id: idCidade }) !== undefined;
    },
    remCidade(idCidade) {
      this.item.cidades = _reject(this.item.cidades, { id: idCidade });
    },
    remTodasCidades() {
      this.item.cidades = [];
    },
    loadCidades(take = 50) {
      get(`/admin/cidades?take=${take}&q=${this.filter}`)
        .then((json) => {
          this.cidades = json.itens;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
    debounceloadCidades: _debounce(function () {
      this.loadCidades();
    }, 300),
    handleSubmit() {
      let request;

      if (!!this.item.id) {
        request = this.update();
      }

      request.catch((error) => {
        this.$swal({
          title: "Ops!",
          text: error.message,
          icon: "error",
        });
      });
    },
  },
  filters: {
    diasEntrega(cidade, diasSemana) {
      let diasEntrega = [];

      _map(diasSemana, (label, chave) => {
        if (cidade[chave]) {
          diasEntrega.push(label);
        }
      });

      return diasEntrega.join(", ");
    },
    legendaDiaSemana(index) {
      let dias = [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sabado",
      ];
      return dias[index];
    },
  },
};
</script>

<style scoped>
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pre-text {
  font-size: 10px;
}
</style>
